// !! Items without popouts must have a link

// To do:
// - Add dandelions, Google Maps projects, 360, dalle, bitmap

const menu = [
  {
    title: "Throw Your Cards Down (Animation)",
    subtitle: "Throwing cards down on a table, using GSAP",
    id: "cardsDown",
    href: "https://codepen.io/mahouhou/full/wvQvGWK",
    classes: ["creative code", "art"],
    src: [
      {
        name: "cards-down",
        format: "png",
        thumbnail: true,
        display: true,
      },
      {
        name: "cards-down-1",
        format: "png",
      },
      {
        name: "cards-down-2",
        format: "png",
      }
    ],
    popout: true,
    tools: ["GSAP", "React", "Javascript", "HTML / CSS"]
  },
  {
    title: "Dandelion Meadow (Drag & Drop)",
    subtitle: "Make art with my art! get a different set of dandelions with every refresh",
    id: "dandelionMeadow",
    classes: ["creative code", "art"],
    src: [
      {
        name: "dandelions",
        format: "png",
        thumbnail: true,
        display: true,
      },
    ],
    popout: true,
    tools: ["React", "Javascript", "HTML / CSS", "Konva"]
  },
  {
    title: "Rain Falling (Animation)",
    subtitle: "Randomness in nature: emulating patterns of rain fall with Javascript",
    id: "rainFalling",
    href: "https://frejachristiana.com/symmetry/rain/falling.html",
    classes: ["creative code", "art"],
    src: [
      {
        name: "rain-falling-blue",
        format: "png",
        thumbnail: true,
        display: true,
      },
      {
        name: "rain-falling-green",
        format: "png",
      },
    ],
    popout: true,
    tools: ["React", "Javascript", "HTML / CSS"]
  },
  {
    title: "Surreal Bot",
    subtitle:
      "Surreal micro stories: a tiny project to learn about making bots while having a bit of a laugh",
    id: "surrealBot",
    href: "https://botsin.space/@surrealbot",
    classes: ["creative code", "art"],
    popout: true,
    tools: ["Ephemeral Writing", "CheapBotsTootSweet"]
  },
  {
    title: "BaumKrone",
    subtitle:
      "Seasonal web design: design and development for Berlin-based tree surgery business",
    id: "baumKrone",
    href: "https://baumkrone-berlin.de",
    classes: [
      "commissions",
      "frontend development",
      "creative code",
      "ui / ux",
      "branding"
    ],
    src: [
      {
        name: "baumkrone",
        format: "jpg",
        thumbnail: true,
        display: false,
      },
      {
        name: "baumkrone-blossom",
        format: "png",
      },
      {
        name: "baumkrone-body",
        format: "png",
      },
      {
        name: "baumkrone-cherries",
        format: "png",
      },
      {
        name: "baumkrone-pines",
        format: "png",
      },
    ],
    popout: true,
    description: [
      "The brief was to design an elegant business website that's in some way inspired by trees (e.g. texture, form, growth), and as light-weight and 'green' as possible.",
      "I answered the brief by bringing seasonality to the design. The site's colour palette fluctuates with the lunar cycle and the header image changes with the seasons. I also designed a light/ dark mode switch for browsing under different light conditions.",
      "I chose React for its speed and flexibility. All icons are rendered in SVG, again for speed; and we went for 1 impactful raster image per page download instead of a full gallery, to keep page size to a minimum.",
      "This website gets a desktop score of 100 and a mobile score of 88 on Google Page Insights. It's cleaner than 89% of websites tested by the Website Carbon Calculator.",
    ],
    tools: ["React", "Javascript", "HTML / CSS", "SunCalc", "React Markdown"],
  },
  {
    title: "NYU Tisch",
    subtitle:
      "Programming repetition: UX and web development for the 2nd year film showcase",
    id: "nyuTisch",
    href: "https://2ndyearshowcase.com",
    classes: ["commissions", "frontend development", "ui / ux"],
    src: [
      {
        name: "nyu-dont-you-dare-film-me-now",
        format: "jpg",
        thumbnail: true,
        display: false,
      },
      {
        name: "nyu-filmmakers",
        format: "png",
      },
      {
        name: "nyu-cade",
        format: "png",
      },
      {
        name: "nyu-trailer",
        format: "png",
      },
      {
        name: "nyu-tickets",
        format: "png",
      },
    ],
    popout: true,
    description: [
      "I was commissioned to develop a light single-page website to showcase films directed by Tisch 2nd year students. As I was given a tight timeline, it was imperative that I find ways to do more with less by programming as much as possible.",
      "I chose React to help me generate components based on film students' profile data. This separated the content from the design which made both aspects easier and therefore faster to edit.",
      "I developed the website from designs provided me by NYU Tisch via the 2nd year class, I then responded to feedback together with them in person. This saved us a lot of time on back-and-forth communication and ensured we were always on the same page.",
    ],
    tools: ["React", "Javascript", "HTML / CSS"],
  },
  {
    title: "Dr Thivi",
    subtitle:
      "Clean and beautiful UI: design and development for new book launch by leading nutrition and skin health expert",
    id: "drThivi",
    href: "https://drthivi.com",
    classes: ["commissions", "frontend development", "ui / ux"],
    src: [
      {
        name: "dr-thivi-maruthappu",
        format: "jpg",
        thumbnail: true,
        display: false,
      },
      {
        name: "drthivi-lemons",
        format: "png",
      },
      {
        name: "drthivi-book",
        format: "png",
      },
      {
        name: "drthivi-form",
        format: "png",
      },
      {
        name: "drthivi-skincare",
        format: "png",
      },
      {
        name: "drthivi-skinfood-approach",
        format: "png",
      },
    ],
    popout: true,
    description: [
      "Dr Thivi had an upcoming book launch and needed a website to promote it. She was looking for a fresh look that complemented her new brand photography and handdrawn illustrations.",
      "We worked together to choose a colour palette and typography that inspired feelings of lightness and calm. I then designed a small component library and prototype that would showcase Thivi's imagery while illustrating the copy.",
      "To develop this website, I chose Elementor Pro with Wordpress. I was able to translate my prototype designs into a full website very quickly and respond to client feedback with ease.",
    ],
    tools: [
      "Wordpress",
      "Elementor Pro",
      "Figma",
      "HTML / CSS",
      "Adobe Photoshop",
    ],
  },
  {
    title: "Maple & Co",
    subtitle:
      "Office vs home delivery: designing multiple different customer journeys inside one website",
    id: "mapleAndCo",
    href: "https://mymapleandco.com",
    classes: ["commissions", "frontend development", "ui / ux"],
    src: [
      {
        name: "mapleandco-picnic",
        format: "jpg",
        thumbnail: true,
        display: false,
      },
      {
        name: "maple-hero",
        format: "png",
      },
      {
        name: "maple-locations",
        format: "png",
      },
      {
        name: "maple-form",
        format: "png",
      },
      {
        name: "maple-menu",
        format: "png",
      },
      {
        name: "maple-office",
        format: "png",
      },
      {
        name: "maple-video",
        format: "png",
      },
      {
        name: "maple-insta",
        format: "png",
      },
    ],
    popout: true,
    description: [
      "Maple & Co were looking to refresh their branding and redesign their website. Adria got in touch with me to create a new website that would allow customers to browse Maple's full menu, find their nearest location and follow through to their preferred delivery platform.",
      "I chose Wordpress with Elementor Pro for this project to achieve a balance between flexibility and usability, since it was important that non-coders from their marketing team would be able to update the website.",
      "Together we identified all the different types of components the website would need. Once Maple were happy, I set about realising my designs. In the case of the location finder, this required a plugin I hadn't used before so instead of attempting to prototype the component in advance, I built this one directly with Elementor while responding to client feedback.",
    ],
    tools: [
      "Wordpress",
      "Elementor Pro",
      "Figma",
      "Klaviyo",
      "Google Analytics",
      "WP Maps Pro",
      "HMTL / CSS",
    ],
  },
  {
    title: "crypto-transfer.co.uk",
    subtitle:
      "Deliberately dodgy: developing a shady looking website to simulate a crypto-currency transfer for award winning video game Unknown Number",
    id: "cryptoTransfer",
    href: "https://crypto-transfer.co.uk",
    classes: ["commissions", "frontend development", "creative code", "ui / ux"],
    src: [
      {
        name: "crypto-unknown-number",
        format: "webp",
        thumbnail: true,
        display: false,
      },
      {
        name: "crypto-popup",
        format: "png",
      },
      {
        name: "crypto-home",
        format: "png",
      },
      {
        name: "crypto-welcome",
        format: "png",
      },
      {
        name: "crypto-form",
        format: "png",
      },
      {
        name: "crypto-progress",
        format: "png",
      },
    ],
    popout: true,
    description: [
      "I was commissioned by indie studio Godolphin Games to develop their finale experience for video game 'Unknown Number: A First Person Talker'. In order to complete the mission, the player must transfer a sum of fictional crypto-currency to a mysterious child eco-activist group. My challenge was to create this experience and ensure its accessibility via the in-game web browser.",
      "I chose to build this website with React for its instant, game-like feel. The client team provided me with designs for the UI and hi-res assets that I could adjust to fit.",
      "My work included a video component optimised for all modern browsers including a Chromium-based in-game browser, a loading screen for aforementioned video component, 'password' validation for a form and a progress bar with a counter.",
    ],
    tools: [
      "React",
      "Javascript",
      "HTML / CSS",
      "Valtio State Management",
      "React Player",
      "React Device Detect",
    ],
  },
  {
    title: "A Different Phone",
    subtitle:
      "Javascript audio: an interactive audio experience developed for award winning video game Unknown Number",
    id: "asciiPhone",
    href: "https://adifferentphone.sligoil.com",
    classes: ["commissions", "frontend development", "creative code", "ui / ux"],
    src: [
      {
        name: "different-phone",
        format: "png",
        thumbnail: true,
        display: false,
      },
      {
        name: "different-phone-in-game",
        format: "png",
      },
      {
        name: "different-phone-dial-square",
        format: "png",
      },
      {
        name: "different-phone-wrong-number-square",
        format: "png",
      },
    ],
    popout: true,
    description: [
      "This application was designed to include international customers playing the video game Unknown Number. Part of the game involves dialling a real UK phone number on the player's actual phone in order to listen to a prerecorded message; it seems unfair for players to pay an extra fee of an arbitrary amount depending where they live. To fix this, I was asked to develop a web app that would simulate the phone call experience and play the same prerecorded message. The app is accessible online via the in-game web browser as well as all modern browsers.",
      "I was provided with the ASCII phone art and audio files. With these assets, I then developed a class of methods that include a handleClick method to store dialled numbers in the state, a method to retrieve the first 65 characters from this state and display it inside the phone screen, a handleCall method to check the dialled number and conditionally put the call through or otherwise play a 'wrong number' audio message, as well as a clearDial method to cancel the call, reset the phone and cut off any audio messages or animations.",
      "To add visual confirmation when a player dials a correct number, I adapted some of artist Andreas Gysin's animated ASCII code to play in the background.",
    ],
    tools: ["React", "Javascript", "HTML / CSS", "ASCII Playground"],
  },
  {
    title: "Telling Eros",
    subtitle:
      "Tiered membership: a pay-walled content library for women's erotica startup Telling Eros",
    id: "tellingEros",
    classes: ["commissions", "frontend development", "ui / ux", "branding"],
    src: [
      {
        name: "telling-eros",
        format: "jpg",
        thumbnail: true,
        display: false,
      },
      {
        name: "telling-eros-coffeeshop",
        format: "jpg",
      },
      {
        name: "telling-eros-preview",
        format: "jpg",
      },
      {
        name: "telling-eros-membership",
        format: "jpg",
      },
      {
        name: "telling-eros-checkout",
        format: "jpg",
      },
      {
        name: "telling-eros-submit",
        format: "jpg",
      },
    ],
    popout: true,
    description: [
      "I assisted women's erotica startup Telling Eros with their logo design and branding, UI and UX design, as well as the development of their online content membership platform.",
      "In keeping with the brand name, the look and feel was inspired by the natural pigments of ancient Greek wall paintings. The butterfly logo is a reference to the Greek God Eros' wife Psyche, who has often been symbolised by a butterfly.",
      "We decided to keep development and maintenance costs down by developing with Wordpress and membership plugin Ultimate Member. By combining and customising a variety of software, I was able to create a tiered membership system that allows readers to browse a limited selection for free, or choose one of two memberships for extra content and perks.",
      "My work also included a content library with a search bar, category and tag filters, automated emails, a rating and reviews system for readers to feedback on what they've read (which also feeds into the content library filters), plus a whole other system for writers with application and submission forms.",
    ],
    tools: [
      "Wordpress",
      "Woocommerce",
      "Woocommerce Subscriptions",
      "Stripe",
      "Ultimate Member",
      "Multi Rating Pro",
      "Content Views",
      "User Submitted Posts",
      "Mailchimp",
      "HTML / CSS",
      "Adobe Illustrator",
      "MAMP",
    ],
  },
  {
    title: "Snake Minigame",
    subtitle:
      "Snake but make it Sligoil: a minigame adaption for the Sligoil intranet as part of the award winning video game Unknown Number",
    id: "snakeGame",
    href: "https://rig14.sligoil.com/wp-content/themes/twentytwentyone-child-theme/minigame/index.html?snake",
    classes: ["commissions", "frontend development", "creative code"],
    src: [
      {
        name: "snake-game",
        format: "png",
        thumbnail: true,
        display: false,
      },
      {
        name: "snake-game-game-over-wall",
        format: "png",
      },
      {
        name: "snake-game-ready",
        format: "png",
      },
      {
        name: "snake-game-play",
        format: "png",
      },
      {
        name: "snake-game-game-over-body",
        format: "png",
      },
    ],
    popout: true,
    description: [
      "The purpose of this minigame was to add a little extra fun to the Sligoil instranet. Sligoil is a fictional oil corporation from the Unknown Number story for which I also created a brand website and password protected intranet. This intranet is a source of information required to complete various in-game missions.",
      "To add to the retro/ web 2.0 style of the video game, the team were looking for an arcade-style snake game that could be played online via the in-game browser. To fulfill the brief, I borrowed code from the Javascript Crisp Game Library by ABA Games. This library provides excellent documentation and I was able to add some customisations including coins, a snake body that spells out the letters of a web address one letter at a time with each coin consumed, and a custom ending condition that completes the game when the player finishes spelling the web address.",
    ],
    tools: ["Javascript", "Crisp Game Library"],
  },
  {
    title: "Stained Glass (Organic Holes)",
    id: "stainedGlass",
    href: "/holes/stained/glass.html",
    classes: ["creative code", "art"],
    src: [
      {
        name: "stained-glass",
        format: "png",
        thumbnail: true,
        display: true,
      },
    ],
    popout: true,
    description:
      ["Watch a green waterfall through an interactive handdrawn stained glass window.",]
  },
  {
    title: "Fresh Frames (Collage)",
    subtitle:
      "The Droste Effect: creating a website inside a website inside a website with iframes",
    href: "/mygarden/fresh/frames.html",
    id: "freshFrames",
    classes: ["creative code", "art"],
    src: [
      {
        name: "fresh-frames",
        format: "png",
        thumbnail: true,
        display: true,
      },
    ],
    popout: true,
  },
  {
    title: "Brutal Greenhouse (Collage)",
    href: "/mygarden/brutal/greenhouse.html",
    id: "brutalGreenhouse",
    classes: ["creative code", "art"],
    src: [
      {
        name: "brutal-greenhouse",
        format: "png",
        thumbnail: true,
        display: true,
      },
    ],
    popout: true,
  },
  {
    title: "Among Lilac Azaleas (My Garden)",
    href: "/mygarden/among/lilac-azaleas.html",
    id: "lilacAzaleas",
    classes: ["creative code", "art"],
    src: [
      {
        name: "lilac-azaleas",
        format: "png",
        thumbnail: true,
        display: true,
      },
    ],
    popout: true,
  },
  {
    title: "Umbrella Skeleton (Collage)",
    href: "/Cyprus/umbrella/skeleton.html",
    id: "umbrellaSkeleton",
    classes: ["creative code", "art"],
    src: [
      {
        name: "umbrella-skeleton-square",
        format: "png",
        thumbnail: true,
        display: true,
      },
    ],
    popout: true,
  },
  {
    title: "My Birthday Your Birthday (Collage)",
    href: "/Klara/birthday/thirty.html",
    id: "birthdayBirthday",
    classes: ["creative code", "art"],
    src: [
      {
        name: "klara",
        format: "png",
        thumbnail: true,
        display: true,
      }
    ],
    popout: true,
  },
  {
    title: "Machine Fashions Sticker Set (Download)",
    subtitle:
      "A sticker set for Telegram Messenger featuring AI generated faces from thispersondoesnotexist.com",
    href: "https://t.me/addstickers/machinefashions",
    id: "machineFashionsStickers",
    classes: ["research", "art"],
    src: [
      {
        name: "machine-fashions-boy",
        format: "png",
        thumbnail: true,
        display: true,
      },
    ],
    popout: true,
  },
  {
    title: "Public House (Chat)",
    href: "https://www.yourworldoftext.com/~frejachristiana/publichouse",
    id: "pub",
    classes: ["contact", "art"],
    src: [
      {
        name: "public-house",
        format: "png",
        display: true,
      }
    ],
    popout: true,
  },
  {
    title: "Buddleia Mix (Collage)",
    href: "https://wildgarden.neocities.org/",
    id: "buddleiaMix",
    classes: ["creative code", "art"],
    src: [
      {
        name: "buddleia-mix",
        format: "png",
        thumbnail: true,
        display: true,
      }
    ],
    popout: true,
  },
  {
    title: "Magenta (Paint)",
    href: "https://cascading-styles.com/templates/picture/magenta.html",
    id: "magenta",
    classes: ["creative code", "art"],
    src: [
      {
        name: "magenta",
        format: "png",
        thumbnail: true,
        display: true,
      }
    ],
    popout: true,
  },
  {
    title: "Elipse Shift (Paint)",
    href: "/shapes/elipse/shift.html",
    id: "elipseShift",
    classes: ["creative code", "art"],
    src: [
      {
        name: "elipse-shift",
        format: "png",
        thumbnail: true,
        display: true,
      }
    ],
    popout: true,
  },
  {
    title: "Soft Waft (Drag & Drop)",
    subtitle:
      "Make art with my art: an experiment with light mixing to create new colour",
    href: "/collage/soft/waft.html",
    id: "softWaft",
    classes: ["creative code", "art"],
    src: [
      {
        name: "soft-waft",
        format: "png",
        thumbnail: true,
        display: true,
      },
    ],
    popout: true,
  },
  {
    title: "Iris (SVG Map)",
    href: "/map/rainbow/iris.html",
    id: "iris",
    classes: ["creative code", "art"],
    src: [
      {
        name: "iris",
        format: "png",
        thumbnail: true,
        display: true,
      }
    ],
    popout: true,
  },
  {
    title: "Path of Words",
    href: "/words/thousandmiles/path.html",
    id: "pathWords",
    classes: ["creative code", "art"],
    src: [
      {
        name: "path-words-square",
        format: "png",
        thumbnail: true,
        display: true,
      }
    ],
    popout: true,
  },
  {
    title: "Buddleia (Organic Holes)",
    href: "/holes/buddleia/bush.html",
    id: "buddleia",
    classes: ["creative code", "art"],
    src: [
      {
        name: "buddleia",
        format: "png",
        thumbnail: true,
        display: true,
      }
    ],
    popout: true,
  },
  {
    title: "Moon Window & Fungi (Organic Holes)",
    href: "/holes/moon/fungi.html",
    id: "moonWindowFungi",
    classes: ["creative code", "art"],
    src: [
      {
        name: "moon-window-fungi",
        format: "png",
        thumbnail: true,
        display: true,
      }
    ],
    popout: true,
  },
  {
    title: "Liquid Polygon (Collage)",
    href: "/shapes/polygon/liquid.html",
    id: "liquidPolygon",
    classes: ["creative code", "art"],
    src: [
      {
        name: "liquid-polygon",
        format: "png",
        thumbnail: true,
        display: true,
      }
    ],
    popout: true,
  },
  {
    title: "Tiles (Paint)",
    href: "/colour/tiles/flip.html",
    id: "tiles",
    classes: ["creative code", "art"],
    src: [
      {
        name: "tiles",
        format: "png",
        thumbnail: true,
        display: true,
      }
    ],
    popout: true,
  },
  // {
  //   title: "Weave (Paint)",
  //   href: "/colour/weave/interlace.html",
  //   id: "weave",
  //   classes: ["creative code", "art"],
  //   src: [
  //     {
  //       name: "weave",
  //       format: "png",
  //       thumbnail: true,
  //       display: true,
  //     }
  //   ],
  //   popout: true,
  // },
  {
    title: "Waterfalling (Collage)",
    href: "collage/water/fall.html",
    id: "waterfalling",
    classes: ["creative code", "art"],
    src: [
      {
        name: "waterfalling",
        format: "png",
        thumbnail: true,
        display: true,
      }
    ],
    popout: true,
  },
  {
    title: "Quiet Night (Collage)",
    href: "/collage/quiet/night.html",
    id: "quietNight",
    classes: ["creative code", "art"],
    src: [
      {
        name: "quiet-night",
        format: "png",
        thumbnail: true,
        display: true,
      }
    ],
    popout: true,
  },
  {
    title: "Rain (Room)",
    href: "/rooms/rain/shower.html",
    id: "rainRoom",
    classes: ["creative code", "art"],
    src: [
      {
        name: "rain-room",
        format: "png",
        thumbnail: true,
        display: true,
      }
    ],
    popout: true,
  },
  // {
  //   title: "Lightening (Room)",
  //   href: "/rooms/lightening/storm.html",
  //   id: "lighteningRoom",
  //   classes: ["creative code", "art"],
  //   src: [
  //     {
  //       name: "lightening-room",
  //       format: "png",
  //       thumbnail: true,
  //       display: true,
  //     }
  //   ],
  //   popout: true,
  // },
  {
    title: "Leo Submerged (Room)",
    href: "/rooms/leo/submerged.html",
    id: "leoSubmergedRoom",
    classes: ["creative code", "art"],
    src: [
      {
        name: "leo-room",
        format: "png",
        thumbnail: true,
        display: true,
      }
    ],
    popout: true,
  },
  {
    title: "Whats for Dinner?",
    href: "/tasty/dinner.html",
    id: "whatsDinner",
    classes: ["creative code", "art"],
    src: [
      {
        name: "dinner-aubergine",
        format: "png",
        thumbnail: true,
        display: true,
      }
    ],
    popout: true,
  },
  {
    title: "Celestial Hummingbirds (Collage)",
    href: "/collage/celestial/hummingbirds.html",
    id: "celestialHummingbirds",
    classes: ["creative code", "art"],
    src: [
      {
        name: "hummingbirds",
        format: "png",
        thumbnail: true,
        display: true,
      }
    ],
    popout: true,
  },
  {
    title: "Clouds (Collage)",
    href: "/collage/courtyard/clouds.html",
    id: "clouds",
    classes: ["creative code", "art"],
    src: [
      {
        name: "clouds",
        format: "png",
        thumbnail: true,
        display: true,
      }
    ],
    popout: true,
  },
  {
    title: "Unfurls (My Garden)",
    href: "/mygarden/symmetry/unfurls.html",
    id: "unfurls",
    classes: ["creative code", "art"],
    src: [
      {
        name: "unfurls",
        format: "png",
        thumbnail: true,
        display: true,
      }
    ],
    popout: true,
  },
  {
    title: "Azaleas (My Garden)",
    href: "/mygarden/rosetree/azaleas.html",
    id: "azaleas",
    classes: ["creative code", "art"],
    src: [
      {
        name: "azaleas",
        format: "png",
        thumbnail: true,
        display: true,
      }
    ],
    popout: true,
  },
  {
    title: "Council Tax",
    subtitle:
      "A collection of photographs sourced from estate agencies around the world, from 2014 to now",
    href: "https://council-tax.tumblr.com/",
    id: "councilTaxTumblr",
    classes: ["research"],
    src: [
      {
        name: "council-tax-eltham",
        format: "jpg",
        thumbnail: true,
        display: true,
      },
    ],
    popout: true,
  },
  {
    title: "Fresh Towels",
    subtitle: "An image blog from 2012 - 2015",
    href: "https://fresh-towels.tumblr.com/",
    id: "freshTowelsTumblr",
    classes: ["research"],
    src: [
      {
        name: "fresh-towels-highgate-cemetery",
        format: "jpg",
        thumbnail: true,
        display: true,
      },
    ],
    popout: true,
  },
  {
    title: "Taiwan Dollar",
    subtitle: "A travel blog and photography journal from Taiwan and beyond",
    href: "https://taiwandollar.tumblr.com/",
    id: "taiwanDollarTumblr",
    classes: ["research", "art"],
    src: [
      {
        name: "taiwan-dollar-city-waterfall",
        format: "jpg",
        thumbnail: true,
        display: true,
      },
    ],
    popout: true,
  },
  {
    title: "Machine Fashions",
    subtitle:
      "Styled by AI: a curated collection of images generated by thispersondoesnotexist.com",
    href: "https://www.instagram.com/machinefashions/",
    id: "machineFashionsInsta",
    classes: ["research"],
    src: [
      {
        name: "machine-fashions-girl",
        format: "png",
        thumbnail: true,
        display: true,
      },
    ],
    popout: true,
  },
  {
    title: "AR Effects",
    href: "https://twitter.com/ar12hitoe",
    id: "areffectsTwitter",
    classes: ["art"],
    popout: true,
  },
  {
    title: "Instagram",
    href: "https://www.instagram.com/frejasmith",
    id: "frejaSmithInsta",
    classes: ["contact", "art"],
  },
  {
    title: "Linkedin",
    href: "https://www.linkedin.com/in/freja-smith/",
    id: "linkedin",
    classes: ["contact"],
  },
  {
    title: "Github",
    href: "https://github.com/mahouhou",
    id: "github",
    classes: ["creative code", "research"],
  },
  {
    title: "Pinterest",
    href: "https://www.pinterest.co.uk/frejachristiana/",
    id: "pinterest",
    classes: ["research"],
    popout: true,
  },
  {
    title: "Youtube",
    href: "https://www.youtube.com/channel/UCj8C3p0NmDMlnTtZ60DL_CQ/featured",
    id: "youtube",
    classes: ["research", "art"],
    popout: true,
  },
  {
    title: "Soundcloud",
    href: "https://soundcloud.com/mahou-5",
    id: "soundcloud",
    classes: ["research"],
  },
  {
    title: "Myanimelist",
    href: "https://myanimelist.net/animelist/mahouhou",
    id: "myanimelist",
    classes: ["research"],
  },
  {
    title: "Cascading Styles",
    href: "https://cascading-styles.com",
    id: "cascading-styles.com",
    classes: ["contact"],
    popout: true,
  },
  {
    title: "commission@frejachristiana.com",
    href: "mailto:comission@frejachristiana.com",
    id: "commissionEmail",
    classes: ["contact"],
  },
  {
    title: "collaborate@frejachristiana.com",
    href: "mailto:collaborate@frejachristiana.com",
    id: "collaborateEmail",
    classes: ["contact"],
  },
];

export default menu;
