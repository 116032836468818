const filterData = [
  {
    id: "art",
    open: false,
    class: true,
  },
  {
    id: "commissions",
    open: false,
    class: true,
  },
  {
    id: "creative code",
    open: false,
    class: true,
  },
  // {
  //   id: "frontend development",
  //   open: false,
  //   class: true,
  // },
  // {
  //   id: "ui / ux",
  //   open: false,
  //   class: true,
  // },
  // {
  //   id: "branding",
  //   open: false,
  // },
  {
    id: "research",
    open: false,
    class: true,
  },
  {
    id: "contact",
    open: false,
    class: true,
  },
];

export default filterData;
