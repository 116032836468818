import { useState, useEffect } from "react";

import "./assets/css/style.css";
import menuData from "./menu"; //List of links
import filterData from "./filter"; //List of filters
import Resume from "./Resume";

function App() {
  //Insert filter.js data into state and track changes to open values
  const [filter, setFilter] = useState(filterData);
  //Insert menu.js into state
  const [menu, setMenu] = useState(menuData);
  //Menu data after it has been filtered according to selected filters
  const [menuFiltered, setMenuFiltered] = useState(menuData);
  //Stores data for clicked menu item, displayed in popout
  const [popState, setPopState] = useState(null);
  //Display 404 message if no menu items are returned
  const [return404, setReturn404] = useState(false);
  //Which filters are open, shortcut for checking filter state
  const [openFilters, setOpenFilters] = useState(null);
  //Hide/ show notice
  const [isNoticeVisible, setIsNoticeVisible] = useState(true);
  //Filter menu for contact items
  const contactMenu = menu.filter((item) =>
    item.classes.includes("contact")
  );
  const [contact, setContact] = useState(contactMenu);
  //Filter menu for non-contact items
  const notContactMenu = menu.filter(
    (item) => !item.classes.includes("contact")
  );
  const [notContact, setNotContact] = useState(notContactMenu);
  
  //Handle hide/ show notice
  const handleNoticeClose = () => {
    setIsNoticeVisible(false);
  };

  //Triggers every time filter state changes
  useEffect(() => {
    //contains selected filters
    let allSelectedFilters = filter.filter((filter) => {
      return filter.open; //Filter for filter open values equal to true
    });
    setOpenFilters(allSelectedFilters);
    //Filter for filters that are open and have a class value of true
    //i.e. not Resume
    let selectedFilters = allSelectedFilters.filter((filter) => {
      return filter.class;
    });

    // Intro();

    //returnedMenuItems contains menu items
    //whose classes match selected filters
    let returnedMenuItems = menu.filter((item) => {
      let answer = false;
      let result = [];
      //For each menu item, map each class onto each selected filter
      //to check if they match
      item.classes.map((classItem) => {
        //If this menu class matches a filter id,
        //push that filter to result array
        return selectedFilters.map((filter) => {
          if (filter.id === classItem.toLowerCase()) {
            //result contains filters that match menu items
            result.push(filter);
          }
          return filter;
        });
      });
      //If result length matches length of selectedFilters, answer is true
      //This menu item therefore matches the filter selection
      if (result.length === selectedFilters.length) {
        //or result.length > 0 for accumulative results
        answer = true;
      }
      return answer;
    });

    //Array of selected filters that doesn't include Contact
    const selectedFiltersSansContact = selectedFilters.filter(
      (filter) => filter.id !== "contact"
    );
    //Return menu items that match all selected filters except Contact
    let filteredSansContact = notContact.filter((item) => {
      let answer = false;
      let result = [];
      //For each menu item, map each class onto each selected filter
      //to check if they match
      item.classes.map((classItem) => {
        //If this menu class matches a filter id,
        //push that filter to result array
        return selectedFiltersSansContact.map((filter) => {
          if (filter.id === classItem.toLowerCase()) {
            //result contains filters that match menu items
            result.push(filter);
          }
          return filter;
        });
      });
      //If result length matches length of selectedFilters, answer is true
      //This menu item therefore matches the filter selection
      if (result.length === selectedFiltersSansContact.length) {
        //or result.length > 0 for accumulative results
        answer = true;
      }
      return answer;
    });

    // If at least 1 filter is selected, including resume
    if (allSelectedFilters.length > 0) {
      // If no menu items are returned
      if (returnedMenuItems.length === 0) {
        // If nothing is returned because Contact filter is selected
        if (filter.find((obj) => obj.id === "contact").open === true) {
          // Show contact items plus otherwise filtered items
          setMenuFiltered(contact.concat(filteredSansContact));
          // If no non-contact itemns are returned
          if (filteredSansContact.length === 0) {
            // Show 404
            setReturn404(true);
          } else {
            // Else, don't show 404
            setReturn404(false);
          }
        } else {
        // If nothing is returned and Contact filter is not selected
        // Show 404 and no menu items
        setMenuFiltered([]);
        setReturn404(true);
        }
      } else {
        // Show returned menu items, no 404
        setReturn404(false);
        setMenuFiltered(returnedMenuItems);
      }
    } else {
      // No filters are selected, show everything, no 404
      setReturn404(false);
      setMenuFiltered(menu);
    }
  }, [filter]);

  function handleFilter(id) {
    //Pass filter id that has been clicked
    let filterTemp = [...filter]; //filterTemp is equal to current filter state
    filterTemp = filterTemp.map((filter) => {
      //Map filter state
      if (filter.id === id) {
        //If a filter id matches clicked filter id
        filter.open = !filter.open; //Set open to true or false
      } //How does React know which filter to open/close? - their ids match
      return filter; //Return new state (whole state or just for updated filter? - whole updated state)
    });
    setFilter(filterTemp); //SetFilter state to updated state
  }

  //Set popState to clicked thumbnail data
  function handlePopout(thumbnail) {
    if (popState && popState.id === thumbnail) {
      // Same thumbnail is clicked again, close the popOut
      closePop();
    } else {
      let popStateTemp = menuFiltered.filter((state) => {
        if (state.id === thumbnail) {
          return true;
        }
        return false;
      });
      setPopState(popStateTemp[0]);
    }
  }

  function closePop() {
    setPopState(null);
  }

  const sizes = [64, 240, 480, 720, 1080];

  //Generate srcset attribute for PopOut image gallery
  const PopSrcSet = ({ item }) => {
    return item.src
      .filter((img) => !img.thumbnail || img.display)
      .map((img) => {
        const srcList = sizes.map(
          (size) =>
            `/images/${item.id}/${img.name}-${size}.${img.format} ${size}w`
        );
        return (
          <img
            alt={item.title}
            srcSet={srcList.join(", ")}
            sizes="calc(80vw - 4rem)"
            src={`/images/${item.id}/${img.name}-240.${img.format}`}
            title={item.title}
          />
        );
      });
  };

  const NavSrcSet = ({ item }) => {
    const thumb = item.src.filter((img) => img.thumbnail)[0];
    const srcList = sizes
      .slice(0, -1)
      .map(
        (size) =>
          `/images/${item.id}/${thumb.name}-${size}.${thumb.format} ${size}w`
      )
      .join(", ");
    return srcList;
  };

  function PopOut({ data }) {
    return (
      <>
        <button className="closePop" onClick={closePop}>
          X
        </button>
        <p className="tags">{data.classes.join(", ")}</p>
        <h2>{data.title}</h2>
        {data.subtitle && <h3>{data.subtitle}</h3>}
        {data.href && (
          <a href={data.href} target="_blank" rel="noreferrer">
            Visit Website &#x279A;
          </a>
        )}
        {data.src && (
          <div className="popGallery">
            <PopSrcSet item={data} />
          </div>
        )}
        {data.description && (
          <div className="popDescription">
            {data.description.map((para) => (
              <p>{para}</p>
            ))}
          </div>
        )}
        {data.tools && (
          <p className="tools">Tools Used: {data.tools.join(", ")}</p>
        )}
      </>
    );
  }

  const Filter = filter.map(
    (
      item //Map filter state for item data
    ) => (
      <li key={item.id} className="filterItem">
        <button
          onClick={() => {
            //onClick call handleClick function
            handleFilter(item.id);
          }}
          className={`${item.open ? "filterOpen" : "filterClosed"}`}
        >
          {item.id}
        </button>
      </li>
    )
  );

  const Nav = menuFiltered.map(
    (
      item //map menuFiltered for item data
    ) => (
      <li
        key={item.id}
        id={item.id}
        className={`gate ${item.popout ? "pop" : "noPop"} ${
          item.src && item.src.find((obj) => obj.thumbnail)
            ? "frame"
            : "noFrame"
        } ${item.classes
          .join(",")
          .replace(/(\s\/\s|\s+)/g, "-")
          .replace(/,/g, " ")}`}
      >
        {item.popout ? (
          item.src && item.src.find((obj) => obj.thumbnail) ? (
            <img
              srcSet={`${NavSrcSet({ item })}`}
              sizes="(max-width: 500px) calc(100vw - 4rem), 480px"
              src={`/images/${item.id}/${
                item.src.find((obj) => obj.thumbnail)?.name
              }-240.${item.src.find((obj) => obj.thumbnail)?.format}`}
              alt={item.title}
              className="thumbnail"
              title={item.title}
              onClick={() => handlePopout(item.id)}
              // loading={item.classes.includes("portfolio" ? "eager" : "lazy")}
            />
          ) : (
            <span onClick={() => handlePopout(item.id)}>{item.title}</span>
          )
        ) : item.src && item.src.find((obj) => obj.thumbnail) ? (
          <a href={item.href} target="_blank" rel="noreferrer">
            <img
              srcSet={`${NavSrcSet({ item })}`}
              sizes="(max-width: 500px) calc(100vw - 4rem), 500px"
              src={`/images/${item.id}/${
                item.src.find((obj) => obj.thumbnail)?.name
              }-240.${item.src.find((obj) => obj.thumbnail)?.format}`}
              alt={item.title}
              className="thumbnail"
              title={item.title}
              // loading={item.classes.includes("portfolio") ? "eager" : "lazy"}
            />
          </a>
        ) : (
          <a href={item.href} target="_blank" rel="noreferrer">
            {item.title} &#x279A;
          </a>
        )}
        {popState && item.id === popState.id && (
          <section id="popWrap">
            <PopOut data={popState} />
          </section>
        )}
      </li>
    )
  );

  // const Intro = () => {
  //   if (
  //     openFilters &&
  //     openFilters.length >= 1 &&
  //     menuFiltered.length > 0 &&
  //     openFilters.find((obj) => obj.id === "portfolio")
  //   ) {
  //     return (
  //       <section id="intro">
  //         <h1>
  //           Creative code & frontend development by Freja Christiana Smith
  //         </h1>
  //         <p>
  //           Hello and welcome to my portfolio. Here you will find a curated
  //           selection of my professional work. <em>For more</em>, try choosing
  //           some categories from the main menu. <em>To view everything</em>{" "}
  //           including personal projects, make sure to unselect all categories.
  //         </p>
  //       </section>
  //     );
  //   } else {
  //     return <></>;
  //   }
  // };

  return (
    <main className="App">
      {/* <header>
        Freja Smith: creative code & frontend development portfolio
      </header> */}
      {/* isNoticeVisible && (<div id="notice">
        <span onClick={handleNoticeClose}>X</span>
        <div>
          <h1>Notice: The purpose of this website has changed</h1>
          <h3>Please visit my new portfolio for my commercial work
            or close this pop up to browse my creative work.</h3>
            <a href="https://portfolio.frejachristiana.com">Visit Portfolio &#x279A;</a>
        </div>
    </div>) */}
      <section id="filterWrap">
        <ul id="filter">{Filter}</ul>
      </section>
      <section id="navWrap">
        {/* {filter.find((obj) => obj.id === "resume").open && <Resume />}
        {openFilters && <Intro />} */}
        {!return404 && <ul id="nav">{Nav}</ul>}
        {return404 && (
          <p id="error404">
            We found nothing that matches your criteria. Please choose something
            else!
          </p>
        )}
      </section>
      <footer></footer>
    </main>
  );
}

export default App;
